body {
  margin: 0;
  font-family: 'Inter', apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f6;
}

/** Hide Osano Cookie Button */
button.osano-cm-window__widget {
  display: none;
}

*,
::before,
::after {
  box-sizing: border-box;
}

/* Action button dropdown */
/* TODO move to the component's mobile design in the ui-kit */
#fluent-default-layer-host .ms-ContextualMenu-Callout {
  @media screen and (max-width: 767px) {
    left: 22px;
    padding: 0;
  }
}

#fluent-default-layer-host .ms-ContextualMenu-itemText {
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }
}

#fluent-default-layer-host .ms-ContextualMenu {
  @media screen and (max-width: 767px) {
    padding: 8px;
  }
}

#fluent-default-layer-host button.ms-ContextualMenu-link {
  @media screen and (max-width: 767px) {
    padding: 12px 8px;
    height: auto;
  }
}
/* /Action button dropdown */

/* Raise the notification toast over sidebar */
.h2o-MessageBarList-root {
  z-index: 9999999;
}

/* Reduced too avoid covering the main nav */
#freshworks-container iframe {
  @media screen and (max-width: 1024px) {
    z-index: 6 !important;
  }
}

/* Orchestrator title appearance animations */
@keyframes title-appearance {
  from {
    transform: translateY(25%);
    color: transparent;
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes description-appearance {
  from {
    color: transparent;
  }
}
